<template>

  <!-- Profit And Cash Flow Income / Expenses -->
  <el-row :gutter="20">

    <ib-blurred-box v-if="subscriptionIsFreeTrial" @upgrade="showUpgradeDialog" />

    <!-- Left Side -->
    <el-col :span="18" class="position-relative">
      <chart-header>
        {{ $t('charts.profitAndLossProjection') }}
        <template #right>
          <!-- Timeline Period Switcher -->
          <timeline-period-switcher-business-plan size="xs" @change="switchChartPeriod($event)" />
          <!-- /Timeline Period Switcher -->
        </template>
      </chart-header>
      <!-- Chart -->
      <profit-and-loss-projection-chart
        :period="period"
        :chart-adjustment="chartAdjustment"
        chart-animations
        hide-title
      />
      <!-- /Chart -->
    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <el-col :span="6">

      <!-- Switch Wrapper Small -->
      <div class="switch-wrapper small">

        <!-- Switch -->
        <div class="switch">

          <!-- Y -->
          <div v-for="year in numberOfYearsToShow" :key="year">
            <input
              :id="'y' + year + '-income-expenses'"
              type="radio"
              class="switch-input"
              name="viewT-expenses2"
              :value="'y' + year"
              :class="{ 'checked': year === selectedYear }"
              :checked="year === selectedYear"
              @click="selectedYear = year"
            >
            <label
              :for="'y' + year + '-income-expenses'"
              :class="['switch-label switch-label-' + getSwitchClass(year), numberOfYearsToShow < 3 ? 'years-number__' + numberOfYearsToShow : '']"
            >
              {{ 'Y' + year }}
            </label>

            <!-- Switch Selection -->
            <span
              v-show="year === selectedYear"
              class="switch-selection"
              :class="'switch-selection__' + numberOfYearsToShow"
            />
            <!-- /Switch Selection -->

          </div>
          <!-- /Y -->

        </div>
        <!-- /Switch -->

      </div>
      <!-- /Switch Wrapper Small -->

      <!-- Year Income, Expenses and Net Profit  -->
      <div class="breakdown">
        <div class="details">

          <!-- Income -->
          <div class="flex">
            <div class="number">
              IN
            </div>
            <div class="name-and-amount">
              <ib-tooltip v-if="Math.abs(yearTotalIncome) >= 1.0e+7" :content="'Total Income: ' + chartTooltipDisplay(yearTotalIncome)" placement="right">
                <p class="bold">
                  {{ chartValueFormatter(yearTotalIncome) }}
                </p>
              </ib-tooltip>

              <p v-else class="bold">
                {{ chartValueFormatter(yearTotalIncome) }}
              </p>

              <p class="text-small">
                {{ $t('totalIncome') }}
              </p>
            </div>
          </div>
          <!-- /Income -->

          <!-- Expenses -->
          <div class="flex">
            <div class="number">
              EX
            </div>
            <div class="name-and-amount">
              <ib-tooltip v-if="Math.abs(yearTotalExpenses) >= 1.0e+7" :content="'Total Expenses: ' + chartTooltipDisplay(yearTotalExpenses)" placement="right">
                <p class="bold">
                  {{ chartValueFormatter(Math.abs(yearTotalExpenses)) }}
                </p>
              </ib-tooltip>

              <p v-else class="bold">
                {{ chartValueFormatter(Math.abs(yearTotalExpenses)) }}
              </p>

              <p class="text-small">
                {{ $t('totalExpenses') }}
              </p>
            </div>
          </div>
          <!-- /Expenses -->

          <!-- Net Profit -->
          <div class="flex">
            <div class="number">
              NP
            </div>
            <div class="name-and-amount">
              <ib-tooltip v-if="Math.abs(yearNetProfit) >= 1.0e+7" :content="'Net Profit: ' + chartTooltipDisplay(yearNetProfit)" placement="right">
                <p class="bold">
                  {{ chartValueFormatter(yearNetProfit) }}
                </p>
              </ib-tooltip>

              <p v-else class="bold">
                {{ chartValueFormatter(yearNetProfit) }}
              </p>

              <p class="text-small">
                {{ $t('charts.netProfit') }}
              </p>
            </div>
          </div>
          <!-- /Net Profit -->

        </div>
      </div>
      <!-- /Year Income, Expenses and Net Profit  -->

    </el-col>
    <!-- /Right Side -->

  </el-row>
  <!-- /Profit And Cash Flow Income / Expenses -->

</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import ProfitAndLossProjectionChart from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/Charts/ProfitAndLossProjectionChart'
import TimelinePeriodSwitcherBusinessPlan from '@/components/Charts/Timeline/TimelinePeriodSwitcherBusinessPlan.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'ProfitAndCashFlowProfitAndLoss',

  components: {
    TimelinePeriodSwitcherBusinessPlan,
    ChartHeader,
    ProfitAndLossProjectionChart
  },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies,
    MixinUserSubscription
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 70
        }
      })
    },
    switchSelector: {
      type: String,
      default: 'profit-and-cash-flow'
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly',
      selectedYear: 1
    }
  },

  computed: {
    yearTotalIncome () {
      let result = 0

      if (
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.yearTotalIncome &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.yearTotalIncome[this.selectedYear]
      ) {
        result = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.yearTotalIncome[this.selectedYear]
      }

      return result
    },

    yearTotalExpenses () {
      let result = 0

      if (
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.yearTotalExpenses &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.yearTotalExpenses[this.selectedYear]
      ) {
        result = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.yearTotalExpenses[this.selectedYear]
      }

      return result
    },

    yearNetProfit () {
      let result = 0

      if (
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit[this.selectedYear]
      ) {
        result = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit[this.selectedYear]
      }

      return result
    }
  },

  methods: {
    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    switchChartPeriod (period) {
      this.$set(this, 'period', period)
    },

    showUpgradeDialog () {
      this.setDialogUpgradeState(true)
    },

    getSwitchClass (year) {
      if (year === 1) {
        return 'off'
      }

      if (year === 2) {
        return 'on'
      }

      return 'on2'
    }
  }
}
</script>
