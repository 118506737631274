<template>

  <!-- Direct Costs Breakdown -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18">
      <chart-header>
        {{ $t('charts.distributionOfDirectCosts') }}
      </chart-header>
      <!-- Chart -->
      <distribution-of-direct-costs-chart
        :chart-adjustment="chartAdjustment"
        chart-animations
        hide-title
      />
      <!-- /Chart -->

    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <el-col :span="6">
      <div class="breakdown">

        <!-- Total Direct Costs -->
        <ib-tooltip v-if="Math.abs(Number(totalDirectCosts)) >= 1.0e+7" :content="'Total Monthly (Average): ' + chartTooltipDisplay(Number(totalDirectCosts))" placement="right">
          <p class="total-costs">
            {{ chartValueFormatter(Number(totalDirectCosts)) }}
          </p>
        </ib-tooltip>

        <p v-else class="total-costs">
          {{ chartValueFormatter(Number(totalDirectCosts)) }}
        </p>
        <!-- /Total Direct Costs -->

        <!-- Total Direct Costs Title -->
        <p class="text-small">
          {{ $t('totalMonthlyAverage') }}
        </p>
        <!-- /Total Direct Costs Title -->

        <!-- Details -->
        <div class="details">

          <!-- Top 3 Direct Costs -->
          <p class="text-small">
            {{ $t('topThreeAverage') }}
          </p>
          <!-- /Top 3 Direct Costs -->

          <!-- Direct Costs -->
          <div
            v-for="(topDirectCost, index) in directCostsAverageData"
            :key="topDirectCost.id"
            class="flex"
          >
            <div class="number">
              {{ index + 1 }}
            </div>
            <div class="name-and-amount">
              <ib-tooltip v-if="Math.abs(Number(topDirectCost.average)) >= 1.0e+7" :content="topDirectCost.name + ': ' + chartTooltipDisplay(Number(topDirectCost.average))" placement="right">
                <p class="bold">
                  {{ chartValueFormatter(Number(topDirectCost.average)) }}
                </p>
              </ib-tooltip>

              <p v-else class="bold">
                {{ chartValueFormatter(Number(topDirectCost.average)) }}
              </p>

              <p class="text-small">
                {{ topDirectCost.name }}
              </p>
            </div>
          </div>
          <!-- Direct Costs -->

        </div>
        <!-- /Details -->

      </div>
    </el-col>
    <!-- /Right Side -->

  </el-row>
  <!-- /Direct Costs Breakdown -->

</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import DistributionOfDirectCostsChart from '@/views/Home/StoryMode/Forecast/DirectCosts/Charts/DistributionOfDirectCostsChart'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCurrencies from '@/mixins/currencies'

export default {
  name: 'DirectCostsBreakdown',

  components: {
    ChartHeader,
    DistributionOfDirectCostsChart
  },

  mixins: [
    MixinApiCalculations,
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 250
        }
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  computed: {
    directCostsAverageData () {
      let result = []

      if (this.$store.state.idea.storyMode.forecast.directCosts.breakdown.data) {
        result = this.$store.state.idea.storyMode.forecast.directCosts.breakdown.data
      }

      return result
    },

    totalDirectCosts () {
      let result = 0

      if (this.$store.state.idea.storyMode.forecast.directCosts.breakdown.total) {
        result = this.$store.state.idea.storyMode.forecast.directCosts.breakdown.total
      }

      return result
    }
  }
}
</script>
