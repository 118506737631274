<template>
  <div v-if="isCoverVisible" class="journal-content cover-content dynamic-height">
    <div class="chapter">

      <!-- Header -->
      <header>
        <editable-title
          v-model="coverData.title"
          tag="h1"
          text-center
          :disabled="!canEdit || shareMode"
          @save="onSave"
        />
      </header>
      <!-- /Header -->

      <!-- Illustration -->
      <div
        class="idea-illustration-wrapper"
        :class="{ 'cursor-pointer': canEdit }"
        @click="onIllustrationClick"
      >
        <div
          v-if="idea.ideaSettings.image"
          class="idea-illustration idea-illustration-settings"
          :style="cardImageBySize(idea.ideaSettings.image, 'medium')"
        />
        <div
          v-else-if="idea.catalogueIdea"
          class="idea-illustration"
          :style="{ backgroundImage: (idea.catalogueIdea !== null && idea.catalogueIdea.cover) ? `url('${baseUrl}${idea.catalogueIdea.cover.path}${idea.catalogueIdea.cover.filename}')` : null }"
        >
          <div
            class="idea-icon"
            :style="{ backgroundImage: (idea.catalogueIdea !== null && idea.catalogueIdea.icon) ? `url('${baseUrl}${idea.catalogueIdea.icon.path}${idea.catalogueIdea.icon.filename}')` : null }"
          />
        </div>
        <div
          v-else
          class="idea-illustration"
          :style="{ backgroundImage: (idea.catalogueCategory !== null && idea.catalogueCategory.cover) ? `url('${baseUrl}${idea.catalogueCategory.cover.path}${idea.catalogueCategory.cover.filename}')` : null }"
        >
          <div
            class="idea-icon"
            :style="{ backgroundImage: (idea.catalogueCategory !== null && idea.catalogueCategory.icon) ? `url('${baseUrl}${idea.catalogueCategory.icon.path}${idea.catalogueCategory.icon.filename}')` : null }"
          />
        </div>
      </div>

      <!-- /Illustration -->

      <!-- Idea Details -->
      <div
        v-if="!hasSubtitle && !editDescriptionMode"
        class="plan-info position-relative"
        :class="planInfoClasses"
        @mouseenter="editDescriptionHover = true"
        @mouseleave="editDescriptionHover = false"
        @click="editDescriptionMode = true"
      >

        <!-- Idea Name -->
        <h1>{{ idea.name }}</h1>
        <!-- /Idea Name -->

        <!-- Catalog Name -->
        <p class="text-lead">
          {{ idea.catalogueIdea ? idea.catalogueIdea.name : idea.catalogueCategory.name }}
        </p>
        <!-- /Catalog Name -->

        <!-- Idea Slogan -->
        <p>{{ idea.slogan }}</p>
        <!-- /Idea Slogan -->
      </div>
      <!-- /Idea Details -->

      <!-- Description section -->
      <template v-else>
        <editable-description
          v-model="coverData.subTitle"
          :disabled="!canEdit || shareMode"
          :limit-characters="5000"
          :default-edit-mode="editDescriptionMode"
          @save="onSave"
          @cancel="onCancel"
        />
      </template>
      <!-- /Description section -->
    </div>
    <!-- /User Info -->

  </div>
</template>

<script>
import EditableDescription from '@/components/_v2/EditableDescription'
import EditableTitle from '@/components/_v2/EditableTitle'
import MixinCountries from '@/mixins/countries'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import journalMethods from '@/mixins/journalMethods'

import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Cover',

  components: {
    EditableTitle,
    EditableDescription
  },

  mixins: [
    journalMethods,
    MixinCountries,
    MixinIdeaRoles
  ],

  props: {
    allPagesTextData: {
      type: Object,
      default: () => ({})
    },
    shareMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      coverData: {},
      editDescriptionMode: false,
      editDescriptionHover: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      hasSubtitle: false
    }
  },

  computed: {
    ...mapState({
      idea: state => state.idea
    }),

    ...mapState({
      user: state => state.user
    }),

    isCoverVisible () {
      const index = this.$store.state.idea.journal.journalSwitchBars.indexOf(
        this.$store.state.idea.journal.journalSwitchBars.find(journalElement => journalElement.sectionName === 'Cover')
      )

      return !this.$store.state.idea.journal.journalSwitchBars[index].options.includes('switch')
    },

    planInfoClasses () {
      return {
        hover: this.editDescriptionHover && !this.editMode
      }
    }
  },

  watch: {
    allPagesTextData () {
      this.coverData = Object.assign({}, this.allPagesTextData)
      this.hasSubtitle = (this.coverData.subTitle !== '')
    }
  },

  mounted () {
    this.coverData = Object.assign({}, this.allPagesTextData)

    this.hasSubtitle = (this.coverData.subTitle !== '')
  },

  methods: {
    ...mapMutations('ui', ['setDialogIdeaCoverState']),

    onSave () {
      this.hasSubtitle = (this.coverData.subTitle !== '')

      this.$emit('save', this.coverData)
    },

    onCancel () {
      this.editDescriptionHover = false
      this.editDescriptionMode = false
    },

    onIllustrationClick () {
      if (this.canEdit) {
        this.setDialogIdeaCoverState(true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.idea-illustration-wrapper {
  padding: 5px;

  &:hover {
    background: #e8e9ed;
  }
}

.plan-info {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  border: 2px solid transparent;
  border-radius: 3px;
  cursor: text;

  &.hover {
    background: #e8e9ed;
  }
}
</style>
