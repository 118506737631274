<template>

  <!-- Other Overhead Breakdown -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18">
      <chart-header>
        {{ $t('charts.distributionOfOtherOverheads') }}
      </chart-header>
      <!-- Chart -->
      <distribution-of-other-overheads-chart
        :chart-adjustment="chartAdjustment"
        chart-animations
        hide-title
      />
      <!-- /Chart -->

    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <el-col :span="6">
      <div class="breakdown">

        <!-- Total Overhead Costs -->
        <ib-tooltip v-if="Math.abs(Number(totalOverheads)) >= 1.0e+7" :content="'Total Monthly (Average): ' + chartTooltipDisplay(Number(totalOverheads))" placement="right">
          <p class="total-costs">
            {{ chartValueFormatter(Number(totalOverheads)) }}
          </p>
        </ib-tooltip>

        <p v-else class="total-costs">
          {{ chartValueFormatter(Number(totalOverheads)) }}
        </p>
        <!-- /Total Overhead Costs -->

        <!-- Total Overhead Costs Title -->
        <p class="text-small">
          {{ $t('totalMonthlyAverage') }}
        </p>
        <!-- /Total Overhead Costs Title -->

        <!-- Details -->
        <div class="details">

          <!-- Top 3 Overhead Costs -->
          <p class="text-small">
            {{ $t('topThreeAverage') }}
          </p>
          <!-- /Top 3 Overhead Costs -->

          <!-- Overheads Costs -->
          <div v-for="(topOtherOverhead, index) in topOtherOverheads" :key="topOtherOverhead.id" class="flex">
            <div class="number">
              {{ index + 1 }}
            </div>
            <div v-if="topOtherOverhead.average" class="name-and-amount">
              <ib-tooltip v-if="Math.abs(Number(topOtherOverhead.average)) >= 1.0e+7" :content="topOtherOverhead.name + ': ' + chartTooltipDisplay(Number(topOtherOverhead.average))" placement="right">
                <p class="bold">
                  {{ chartValueFormatter(Number(topOtherOverhead.average)) }}
                </p>
              </ib-tooltip>

              <p v-else class="bold">
                {{ chartValueFormatter(Number(topOtherOverhead.average)) }}
              </p>

              <p class="text-small">
                {{ topOtherOverhead.name }}
              </p>
            </div>
          </div>
          <!-- /Overheads Costs -->

        </div>
        <!-- /Details -->

      </div>
    </el-col>
    <!-- /Right Side -->

  </el-row>
  <!-- /Other Overhead Breakdown -->

</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import DistributionOfOtherOverheadsChart from '@/views/Home/StoryMode/Forecast/OtherOverheads/Charts/DistributionOfOtherOverheadsChart'
import MixinCurrencies from '@/mixins/currencies'

export default {
  name: 'OtherOverheadBreakdown',

  components: {
    ChartHeader,
    DistributionOfOtherOverheadsChart
  },

  mixins: [
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 250
        }
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  computed: {
    totalOverheads () {
      let result = 0

      if (this.$store.state.idea.storyMode.forecast.otherOverheads.breakdown.totalOverhead) {
        result = this.$store.state.idea.storyMode.forecast.otherOverheads.breakdown.totalOverhead
      }

      return result
    },

    topOtherOverheads () {
      let result = []

      if (this.$store.state.idea.storyMode.forecast.otherOverheads.breakdown.sorted) {
        result = this.$store.state.idea.storyMode.forecast.otherOverheads.breakdown.sorted
      }

      return result
    }
  }
}
</script>
