<template>
  <!-- Staff And Payroll Breakdown -->
  <el-row :gutter="20">

    <!-- Left Side -->
    <el-col :span="18">
      <chart-header>
        {{ $t('charts.distributionOfPayrollExpenses') }}
      </chart-header>
      <!-- Chart -->
      <distribution-of-payroll-expenses-chart
        :chart-adjustment="chartAdjustment"
        chart-animations
        hide-title
      />
      <!-- /Chart -->
    </el-col>
    <!-- /Left Side -->

    <!-- Right Side -->
    <el-col :span="6">
      <div class="breakdown">

        <!-- Total Staff And Payrolls -->
        <ib-tooltip
          v-if="Math.abs(Number($store.state.idea.storyMode.setUp.staffAndPayrolls.breakdown.total)) >= 1.0e+7"
          :content="'Total Monthly (Average): ' + chartTooltipDisplay(Number($store.state.idea.storyMode.setUp.staffAndPayrolls.breakdown.total))"
          placement="right"
        >
          <p class="total-costs">
            {{ chartValueFormatter(Number($store.state.idea.storyMode.setUp.staffAndPayrolls.breakdown.total)) }}
          </p>
        </ib-tooltip>

        <p v-else class="total-costs">
          {{ chartValueFormatter(Number($store.state.idea.storyMode.setUp.staffAndPayrolls.breakdown.total)) }}
        </p>
        <!-- /Total Staff And Payrolls -->

        <!-- Total Staff And Payrolls Title -->
        <p class="text-small">
          {{ $t('totalMonthlyAverage') }}
        </p>
        <!-- /Total Staff And Payrolls Title -->

        <!-- Details -->
        <div class="details">

          <!-- Top 3 Staff And Payrolls -->
          <p class="text-small">
            {{ $t('topThreeAverage') }}
          </p>
          <!-- /Top 3 Staff And Payrolls -->

          <!-- Staff And Payrolls -->
          <div
            v-for="(staffAndPayroll, index) in $store.state.idea.storyMode.setUp.staffAndPayrolls.breakdown.topStuffs"
            :key="staffAndPayroll.id" class="flex"
          >

            <!-- Year Number -->
            <div class="number">
              {{ index + 1 }}
            </div>
            <!-- /Year Number -->

            <!-- Name & Amount -->
            <div class="name-and-amount">
              <ib-tooltip
                v-if="Math.abs(Number(staffAndPayroll.average)) >= 1.0e+7"
                :content="staffAndPayroll.name + ': ' + chartTooltipDisplay(Number(staffAndPayroll.average))"
                placement="right"
              >
                <p class="bold">
                  {{ chartValueFormatter(Number(staffAndPayroll.average)) }}
                </p>
              </ib-tooltip>
              <p v-else class="bold">
                {{ chartValueFormatter(Number(staffAndPayroll.average)) }}
              </p>
              <p class="text-small">
                {{ staffAndPayroll.name }}
              </p>
            </div>
            <!-- /Name & Amount -->

          </div>
          <!-- Staff And Payrolls -->

        </div>
        <!-- /Details -->

      </div>
    </el-col>
    <!-- /Right Side -->

  </el-row>
  <!-- /Staff And Payroll Breakdown -->
</template>

<script>
import ChartHeader from '@/views/Home/StoryMode/Components/BusinessPlan/ChartHeader'
import DistributionOfPayrollExpensesChart from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/Charts/DistributionOfPayrollExpensesChart'
import MixinCalculations from '@/mixins/calculations'
import MixinCurrencies from '@/mixins/currencies'

export default {
  name: 'StaffAndPayrollBreakdown',

  components: {
    ChartHeader,
    DistributionOfPayrollExpensesChart
  },

  mixins: [
    MixinCalculations,
    MixinCurrencies
  ],

  props: {
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 250
        }
      })
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      forceUpdate: true,
      showTotals: false
    }
  }
}
</script>
